import React from 'react';

import { isArrayWithLength } from '../../shared/utils/validators';

import './tab-as-header.scss';

interface TabAsHeaderProps {
  className?: string;
  onTabSelect: (tab: string) => void;
  selectedTab?: string;
  tabs?: string[];
  trailingContent?: () => JSX.Element | null;
}

function TabAsHeader(props: TabAsHeaderProps) {
  const {
    className,
    tabs = [],
    selectedTab,
    onTabSelect,
    trailingContent
  } = props;

  return (
    <div className={`${className || ''} tab-as-header`} styleName="header">
      <div className="tab-as-header__list" role="tablist" styleName="tab-list">
        {isArrayWithLength(tabs) &&
          tabs.map(tab => (
            <button
              aria-selected={tab === selectedTab}
              key={`tab-${tab}`}
              onClick={() => onTabSelect(tab)}
              role="tab"
              styleName={tab === selectedTab ? 'tab--selected' : 'tab'}
            >
              {tab}
            </button>
          ))}
      </div>
      {typeof trailingContent === 'function' && trailingContent()}
    </div>
  );
}

export default React.memo(TabAsHeader);
